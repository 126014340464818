import { css } from "styled-components"
import device from "particles/mediaQueries"

const button = css`
  button {
    cursor: pointer;
  }
`

export default button
