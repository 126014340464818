import { css } from "styled-components"
import FDir from "particles/fonts"
import device from "particles/mediaQueries"

const fonts = css`
  @font-face {
    font-family: "Circular";
    src: url(${FDir.CircularRegularEOT}); /* IE9 Compat Modes */
    src: url(${FDir.CircularRegularEOTIE}) format("embedded-opentype"),
      /* IE6-IE8 */ url(${FDir.CircularRegularWOFF2}) format("woff2"),
      /* Super Modern Browsers */ url(${FDir.CircularRegularWOFF})
        format("woff"),
      /* Pretty Modern Browsers */ url(${FDir.CircularRegularTTF})
        format("truetype"); /* Safari, Android, iOS */
    font-style: normal;
    font-weight: 400;
  }

  @font-face {
    font-family: "Circular";
    src: url(${FDir.CircularMediumEOT}); /* IE9 Compat Modes */
    src: url(${FDir.CircularMediumEOTIE}) format("embedded-opentype"),
      /* IE6-IE8 */ url(${FDir.CircularMediumWOFF2}) format("woff2"),
      /* Super Modern Browsers */ url(${FDir.CircularMediumWOFF}) format("woff"),
      /* Pretty Modern Browsers */ url(${FDir.CircularMediumTTF})
        format("truetype"); /* Safari, Android, iOS */
    font-style: normal;
    font-weight: 500;
  }

  @font-face {
    font-family: "Circular";
    src: url(${FDir.CircularBoldEOT}); /* IE9 Compat Modes */
    src: url(${FDir.CircularBoldEOTIE}) format("embedded-opentype"),
      /* IE6-IE8 */ url(${FDir.CircularBoldWOFF2}) format("woff2"),
      /* Super Modern Browsers */ url(${FDir.CircularBoldWOFF}) format("woff"),
      /* Pretty Modern Browsers */ url(${FDir.CircularBoldTTF})
        format("truetype"); /* Safari, Android, iOS */
    font-style: normal;
    font-weight: 700;
  }
`

export default fonts
