import { createGlobalStyle } from "styled-components"
import "normalize.css/normalize.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import device from "./mediaQueries"

import boxSizing from "./styles/setup/box-sizing"
import fonts from "./styles/setup/fonts"

import anchor from "./styles/base/anchor"
import blockquote from "./styles/base/blockquote"
import body from "./styles/base/body"
import button from "./styles/base/button"
import form from "./styles/base/form"
import heading from "./styles/base/heading"
import html from "./styles/base/html"
import image from "./styles/base/image"
import table from "./styles/base/table"
import wrapper from "./styles/base/wrapper"

import embed from "./styles/components/embed"
import grid from "./styles/components/grid"

import display from "./styles/utilities/display"
import hide from "./styles/utilities/hide"
import text from "./styles/utilities/text"

const GlobalStyle = createGlobalStyle`
	${fonts}
	${boxSizing}

	${anchor}
	${blockquote}
	${body}
	${button}
	${heading}
	${html}
	${form}
	${image}
	${table}
	${wrapper}

	${embed}
	${grid}

	${display}
	${hide}
	${text}
`

export default GlobalStyle
