import { css } from "styled-components"
import device from "particles/mediaQueries"

const body = css`
  ::-webkit-scrollbar {
    width: 15px;
    background-color: ${(props) => props.theme.grey800};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.grey600};
    border: 2px solid ${(props) => props.theme.grey600};
  }

  body,
  #root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  body {
    overflow-x: hidden;

    background-color: ${(props) => props.theme.black};
    color: ${(props) => props.theme.white};
    font-family: ${(props) => props.theme.fontPrimary};
    font-size: 1.8rem;
    line-height: 1.5;
    scroll-behavior: smooth;

    @media ${device.xl} {
      font-size: 2rem;
    }
  }
`

export default body
