module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/storybook/assets/images/favicon/android-chrome-512x512.png","name":"NoFace","short_name":"NoFace","start_url":"/","background_color":"#f7f0eb","theme_color":"#440099","display":"standalone"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":""},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
