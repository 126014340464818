import { css } from "styled-components"
import device from "particles/mediaQueries"

const blockquote = css`
  blockquote,
  q {
    quotes: "\\201C""\\201D""\\2018""\\2019";
  }

  blockquote {
    margin: 48px 0 32px;
    padding: 0;
    position: relative;

    cite {
      display: block;
      margin-top: 16px;

      color: ${(props) => props.theme.grey200};
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
    }

    p,
    q {
      display: block;
      margin: 0 auto;

      font-size: 20px;

      @media ${device.md} {
        font-size: 26px;
        line-height: 38px;
      }

      &::before,
      &::after {
        position: absolute;

        color: ${(props) => props.theme.grey200};
        font-size: 120px;
        font-weight: 400;
        opacity: 1;
        visibility: hidden;

        @media ${device.md} {
          visibility: visible;
        }
      }

      &::before {
        content: open-quote;
        left: -64px;
        top: -24px;
      }

      &::after {
        bottom: -24px;
        content: close-quote;
        right: -64px;
      }
    }
  }
`

export default blockquote
