import { css } from "styled-components"
import device from "particles/mediaQueries"

const text = css`
  .bold {
    font-weight: bold !important;
  }

  .capitalize,
  .text--capitalize {
    text-transform: capitalize;
  }

  .lowercase,
  .text--lowercase {
    text-transform: lowercase;
  }
`

export default text
