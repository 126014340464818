import { css } from "styled-components"
import device from "particles/mediaQueries"

const display = css`
  .click-through {
    pointer-events: none;
  }

  .display--block {
    display: block;
  }
  .display--inline {
    display: inline;
  }
  .display--inline-block {
    display: inline-block;
  }
`

export default display
