import { css } from "styled-components"
import device from "particles/mediaQueries"
import ButtonReset from "atoms/button/button.reset"

const table = css`
  table {
    border-collapse: collapse;
    overflow-x: auto;
    width: 100%;

    color: ${(props) => props.theme.black};
    text-align: left;

    @media ${device.MXmd} {
      display: block;
    }

    .tc--auto {
      min-width: auto;
    }

    caption {
      padding: ${(props) =>
        props.theme.spacingDefault + ` ` + props.theme.spacingSmall};
      background-color: ${(props) => props.theme.grey700};
      color: ${(props) => props.theme.white};
      font-weight: 700;
      text-align: left;
    }

    td,
    th {
      min-width: 150px;
      padding: ${(props) =>
        props.theme.spacingDefault + ` ` + props.theme.spacingSmall};
    }

    th {
      background-color: ${(props) => props.theme.grey100};
      font-weight: bold;
      letter-spacing: 1.5px;
      text-transform: uppercase;
    }

    tr th[scope="row"] {
      letter-spacing: initial;
      text-transform: initial;
    }

    tr,
    tr th {
      background-color: ${(props) => props.theme.white};
      border-top: 1px solid ${(props) => props.theme.grey200};
      transition: background-color 0.2s ease;

      &:active,
      &:focus,
      &:hover {
        background-color: ${(props) => props.theme.grey200};

        th {
          background-color: ${(props) => props.theme.grey200};
        }

        .table__actions {
          opacity: 1;
        }
      }
    }

    .table__actions {
      @media ${device.md} {
        opacity: 0.2;
        transition: 0.4s all ease;
      }

      button {
        ${ButtonReset}
      }

      a,
      button {
        display: inline-block;

        cursor: pointer;

        &:active,
        &:focus,
        &:hover {
          text-decoration: underline;
        }

        + a,
        + button {
          margin-left: 8px;
          padding-left: 8px;
          border-left: 1px solid ${(props) => props.theme.grey200};
        }
      }
    }

    .table__currency {
      text-align: right;
    }
  }
`

export default table
