import { css } from "styled-components"
import device from "particles/mediaQueries"

const embed = css`
  .embed {
    height: 0;
    position: relative;
    padding-top: 56.25%; /* 16:9 */

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`

export default embed
