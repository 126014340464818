import { css } from "styled-components"
import device from "particles/mediaQueries"

const grid = css`
  .grid {
    margin: 0 auto;
    max-width: 1800px;
    padding: 0 30px;
    width: 100%;

    @media ${device.sm} {
      padding: 0 60px;
    }

    @media ${device.md} {
      padding: 0 10%;
    }

    @media ${device.xl} {
      padding: 0 100px;
    }
  }
`

export default grid
