import { css } from "styled-components"
import device from "particles/mediaQueries"

const form = css`
  /* Form Styles */
  .wrapper {
    button,
    input,
    label,
    textarea {
      border-radius: 3px;
    }

    input {
      max-width: 100%;
    }

    fieldset {
      margin-bottom: 32px;
      padding: 0;

      border: none;
    }

    form {
      margin-bottom: 32px;

      color: ${(props) => props.theme.black};
      font-weight: 500;

      button,
      input,
      select,
      textarea,
      .DateRangePicker {
        margin-top: ${(props) => props.theme.spacingDefault};
      }
    }

    label {
      display: block;
      margin-bottom: ${(props) => props.theme.spacingTiny};
      margin-top: 24px;

      color: ${(props) => props.theme.grey800};
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.1em;
      text-align: left;
      text-transform: uppercase;
    }

    input,
    select,
    textarea,
    .dropzone__area {
      border-radius: ${(props) => props.theme.borderRadiusInput};
      display: block;
      min-height: 56px;
      padding: ${(props) =>
        props.theme.spacingSmall + ` ` + props.theme.spacingDefault};
      width: 100%;

      background-color: ${(props) => props.theme.white};
      border: 1px solid ${(props) => props.theme.grey500};
      color: ${(props) => props.theme.grey800};
      line-height: 1.5;
      transition: border-color 0.25s, box-shadow 0.25s;

      &::placeholder {
        color: ${(props) => props.theme.grey600};
        opacity: 1;
        text-transform: capitalize;
      }

      &[disabled],
      &[readonly] {
        background-color: ${(props) => props.theme.grey300};
        cursor: not-allowed;
      }

      &:focus {
        border: 1px solid ${(props) => props.theme.grey900};
      }
    }

    textarea {
      padding: ${(props) => props.theme.spacingDefault};
    }

    textarea {
      max-width: 100%;
      min-height: 200px;
      resize: vertical;
    }
  }
`

export default form
