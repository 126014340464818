import { css } from "styled-components"
import device from "particles/mediaQueries"

const boxSizing = css`
  /* apply a natural box layout model to all elements, but allowing components to change */
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
`

export default boxSizing
