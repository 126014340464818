export const ThemeDefault = {
  fontPrimary: `"Circular", "Nunito Sans", -apple-system, ".SFNSText-Regular",
      "San Francisco", BlinkMacSystemFont, "Segoe UI", "Helvetica Neue",
      Helvetica, Arial, sans-serif`,
  /* BREAKPOINTS */
  xs: `576px`,
  sm: `768px`,
  md: `992px`,
  lg: `1200px`,
  xl: `1440px`,
  /* BORDERS */
  borderRadiusInput: `3px`,
  /* SPACING */
  spacingTiny: `4px`,
  spacingSmall: `8px`,
  spacingDefault: `16px`,
  spacingLarge: `32px`,
  spacingHuge: `64px`,
  /* COLOURS */
  primary: `#4090D8`,
  secondary: `#6788B2`,
  /* COLOURS - 50 SHADES OF GREY */
  white: `#ffffff`,
  offWhite: `#F9F9F9`,
  grey100: `#EEEFF0`,
  grey200: `#E9EBEC`,
  grey300: `#DAE1E7`,
  grey400: `#B8C2CC`,
  grey500: `#8795A1`,
  grey600: `#606F7B`,
  grey700: `#3D4852`,
  grey800: `#262626`,
  grey900: `#0d0d0d`,
  grey: `#8795A1`,
  black: `#141213`,
  /* COLOURS - BLUE */
  blue100: `#EAF3FB`,
  blue200: `#BFDAF2`,
  blue300: `#95C1E9`,
  blue400: `#6BA9E1`,
  blue500: `#4090D8`,
  blue600: `#2777BF`,
  blue700: `#1E5C94`,
  blue800: `#16426A`,
  blue900: `#0D2840`,
  blue: `#4090D8`,
  /* COLOURS - PALE BLUE */
  pale100: `#EEF2F6`,
  pale200: `#CCD7E5`,
  pale300: `#AABDD4`,
  pale400: `#89A2C3`,
  pale500: `#6788B2`,
  pale600: `#4D6E98`,
  pale700: `#3C5676`,
  pale800: `#2B3D55`,
  pale900: `#1A2533`,
  pale: `#6788B2`,
  /* COLOURS - GREEN */
  green100: `#eeffe5`,
  green200: `#cdffb3`,
  green300: `#abff80`,
  green400: `#89ff4d`,
  green500: `#68ff1a`,
  green600: `#4ee600`,
  green700: `#3db300`,
  green800: `#2b8000`,
  green900: `#1a4d00`,
  green: `#3db300`,
  greenRGB: `78,230,0`,
  /* COLOURS - PURPLE */
  purple100: `#ede5ff`,
  purple200: `#c8b3ff`,
  purple300: `#a480ff`,
  purple400: `#7f4dff`,
  purple500: `#5b1aff`,
  purple600: `#4100e6`,
  purple700: `#3300b3`,
  purple800: `#240080`,
  purple900: `#16004d`,
  purple: `#5b1aff`,
  purpleRGB: `91,26,255`,
  /* COLOURS - RED */
  red100: `#ffe5e5`,
  red200: `#ffb3b3`,
  red300: `#ff8080`,
  red400: `#ff4d4d`,
  red500: `#ff1a1a`,
  red600: `#e60000`,
  red700: `#b30000`,
  red800: `#800000`,
  red900: `#4d0000`,
  red: `#ff1a1a`,
}

export default ThemeDefault
