import { css } from "styled-components"
import device from "particles/mediaQueries"

const image = css`
  img,
  video {
    max-width: 100%;
  }
`

export default image
