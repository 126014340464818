import { css } from "styled-components"
import device from "particles/mediaQueries"

const wrapper = css`
  .wrapper {
    flex: 1;
    margin: 0 auto;
    overflow: hidden;
    width: 100%;

    /* Fix anchor scroll positioning */
    [id]::before {
      display: block;
      content: "";
      margin-top: -4rem;
      height: 4rem;
    }

    > *[data-index="0"] {
      padding-top: 114px;

      @media ${device.md} {
        padding-top: 0;
      }
    }
  }
`

export default wrapper
